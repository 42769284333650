<template>
  <div class="join">
    <my-swiper :bannerList="bannerList"></my-swiper>
    <div class="yxj-container" style="overflow: visible">
      <div class="join-container page-container swer">
        <div class="qr-area">
          <div class="qr-center mobile-qr box" v-for="(item, index) in pageObj" :key="index">
            <div class="wx-tips">{{item.title}}</div>
            <div class="wx-label">
              <p v-if="!$store.getters.mobile" v-html="item.info"></p>
              <p v-else>{{item.mobileInfo}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Join',
  data () {
    return {
      pageObj: [
        {
          title: '以奋斗者为本',
          info: '采用人力资源优先战略，以敬业、<br/>专业、恒业的员工为最宝贵资产，<br/>让奋斗者得到优良回报。',
          mobileInfo: '采用人力资源优先战略，以敬业、专业、恒业的员工为最宝贵资产，让奋斗者得到优良回报。'
        },
        {
          title: '帮助客户成功',
          info: '帮助医生用户更高效的学习和工作，<br/>帮助创新药卓越营销，帮助基层医院<br/>提升专科服务能力。',
          mobileInfo: '帮助医生用户更高效的学习和工作，帮助创新药卓越营销，帮助基层医院提升专科服务能力。'
        },
        {
          title: '高标准交付',
          info: '以平台化、插件化、工具化软件，辅以<br/>内部复杂、用户简单的专业服务，提供<br/>高于客户预期的服务交付。',
          mobileInfo: '以平台化、插件化、工具化软件，辅以内部复杂、用户简单的专业服务，提供高于客户预期的服务交付。'
        },
        {
          title: '持续提升效率',
          info: '杜绝浪费，节省每一分钱；十倍兵力投<br/>资于战略突破口；持续迭代、不断优<br/>化，提升客户价值。',
          mobileInfo: '杜绝浪费，节省每一分钱；十倍兵力投资于战略突破口；持续迭代、不断优化，提升客户价值。'
        },
      ],
      bannerList: [
        {
          to: '/culture',
          img: require('@/assets/images/banner07.jpg'),
          mobileImg: require('@/assets/images/banner07m.png'),
          info: '2020年1月，医学界“卓越鼠年”辞旧迎新全员会'
        },
        {
          to: '/culture',
          img: require('@/assets/images/banner08.jpg'),
          mobileImg: require('@/assets/images/banner08m.png'),
          info: '2020年7月31日，海南三亚·营销备战会'
        },
        {
          to: '/culture',
          img: require('@/assets/images/banner10.jpg'),
          mobileImg: require('@/assets/images/banner10m.png'),
          info: ' 2020年9月4-5日，媒体与医生服务部年中会议'
        },
        {
          to: '/contact',
          img: require('@/assets/images/banner19.jpg'),
          mobileImg: require('@/assets/images/banner19m.png'),
          info: '2019年6月1日，“快乐六一”主题亲子活动'
        }
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
